const shoreMore = document.querySelector('.example__btn-show');
const hide = document.querySelector('.example__btn--closed');
// let items = 4;
let item = 3;
// let item768 = 2;
let length = document.querySelectorAll('.example__item').length;
console.log(length)

// if (window.matchMedia('(max-width: 768px)').matches) {
//   shoreMore.addEventListener('click', () => {
//     shoreMore.classList.remove('example__closed');
//     shoreMore.classList.remove('example__btn--closed-active');
//     item768 += 2;
//     const array = Array.from(document.querySelector('.example__list').children);
//     const visItems = array.slice(0, item768);
//     console.log(array)
//     console.log(visItems)
//     visItems.forEach(el => el.classList.add('is-visible'))
//     if (visItems.length === length) {
//       shoreMore.classList.add('example__closed');
//       hide.classList.add('example__btn--closed-active')
//     }
//   })
//   hide.addEventListener('click', () => {
//     shoreMore.classList.remove('example__closed');
//     shoreMore.classList.remove('example__btn--closed-active');
//     item768 += 2;
//     const array = Array.from(document.querySelector('.example__list').children);
//     const visItems = array.slice(0, item768);
//     console.log(array)
//     console.log(visItems)
//     visItems.forEach(el => el.classList.add('is-visible'))
//   })
// }
if (window.matchMedia('(max-width: 1024px)').matches) {
  if (window.matchMedia('(min-width: 769px)').matches) {
    shoreMore.addEventListener('click', () => {
      shoreMore.classList.remove('example__closed');
      shoreMore.classList.remove('example__btn--closed-active');
      item += 3;
      console.log(item)

      const array = Array.from(document.querySelector('.example__list').children);
      const visItems = array.slice(0, item);
      visItems.forEach(el => el.classList.add('is-visible'))
      if (visItems.length === length) {
        shoreMore.classList.add('example__closed');
        hide.classList.add('example__btn--closed-active');
      }
 })

    hide.addEventListener('click', () => {
      shoreMore.classList.remove('example__closed');
      hide.classList.remove('example__btn--closed-active');
      const array = Array.from(document.querySelector('.example__list').children);
      const visItems = array.slice(0, item);
      visItems.forEach(el => el.classList.remove('is-visible'));
      item = 3;

    })
  }
}

// if (window.matchMedia('(min-width: 1025px)').matches) {
//   shoreMore.addEventListener('click', () => {
//     shoreMore.classList.remove('example__closed');
//     hide.classList.remove('example__btn--closed-active');
//     items += 4;
//     const array = Array.from(document.querySelector('.example__list').children);
//     const visItems = array.slice(0, items);
//     visItems.forEach(el => el.classList.add('is-visible'))
//     console.log(visItems)
//     if (visItems.length === length) {
//       shoreMore.classList.add('example__closed');
//       hide.classList.add('example__btn--closed-active');
//     }
//   })
//  if (window.matchMedia('(min-width: 1025px)').matches) {
//   hide.addEventListener('click', () => {

//     shoreMore.classList.remove('example__closed');
//     hide.classList.remove('example__btn--closed-active');
//     const array = Array.from(document.querySelector('.example__list').children);
//     const visItems = array.slice(0, items);
//     visItems.forEach(el => el.classList.remove('is-visible'))
//     items = 4;
//   })
//  }

// }

function shore(media, item, items) {
  if (window.matchMedia(media).matches) {
    shoreMore.addEventListener('click', () => {
      shoreMore.classList.remove('example__closed');
      hide.classList.remove('example__btn--closed-active');
      items += item;
      const array = Array.from(document.querySelector('.example__list').children);
      const visItems = array.slice(0, items);
      visItems.forEach(el => el.classList.add('is-visible'))
      if (visItems.length === length) {
        shoreMore.classList.add('example__closed');
        hide.classList.add('example__btn--closed-active')
      }
    })
    hide.addEventListener('click', () => {
      shoreMore.classList.remove('example__closed');
      hide.classList.remove('example__btn--closed-active');
      const array = Array.from(document.querySelector('.example__list').children);
      const visItems = array.slice(0, items);
      visItems.forEach(el => el.classList.remove('is-visible'));
      items = item;
    })
  }
}

shore('(min-width: 1025px)', 4, 4)
shore('(max-width: 768px)', 2, 2)
